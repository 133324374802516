









































import {Component, Emit, Mixins, Prop, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";
import IAskuePointsByObject
  from "@/models/consumption-table/AskuePointsByObject.interface";
import IAskuePoint from "@/models/consumption-table/AskuePoint.interface";
import IChosenAskuePointProps
  from "@/models/consumption-table/ChosenAskuePointProps.interface";
import ITableHeader from "@/models/table/Header.interface";
import AppApiMixin from "../mixins/AppApi.vue";
import DocGetterMixins from "../mixins/DocGetter.vue";
import DropdownWithDatePickerWithoutRange
  from "../hoc/DropdownWithDatePickerWithoutRange.vue";
import XButton from "../SimpleButton.vue";
import XDataTable from "../hoc/Table.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";
import IChosenAsdPointProps
  from "@/models/consumption-table/ChosenAsdPointProps.interface";
import IAsdPointsByObject
  from "@/models/consumption-table/AsdPointsByObject.interface";

@Component({
  components: {
    XDataTable,
    XDropdownWithRadioButtons,
    DropdownWithDatePickerWithoutRange,
    XButton,
  },
  computed: {...mapGetters("user", {openMonth: "month"})},
})
class AskueConsumptionTable extends Mixins(AppApiMixin, DocGetterMixins) {
  @Prop({required: true}) readonly pointsByObject!: IAsdPointsByObject;

  @Emit("update:point")
  public emitUpdatePoint(payload: IChosenAsdPointProps) {
    this.$emit("update:point", payload);
  }

  @Emit("update:wall")
  public emitUpdateWall(show: boolean): void {
    this.$emit("update:wall", show);
  }

  @Watch("wasChangeSyncValue")
  public syncValueChanged(): void {
    const {dateDropdown, modeDropdown, docTypeDropdown} = this;

    if (dateDropdown.show || modeDropdown.show || docTypeDropdown.show) {
      return this.emitUpdateWall(true);
    }

    this.emitUpdateWall(false);
  }

  data() {
    const headers: ITableHeader[] = [
      {text: "", value: "pointName"},
      {text: "", value: "action"},
    ];

    const modeDropdown = {
      items: ["Факт"],
      selectedItemIndex: 0,
      show: false,
    };

    const dateDropdown = {
      date: "",
      show: false,
    };

    const docTypeDropdown = {
      selectedItemIndex: 0,
      show: false,
    };

    return {
      dateDropdown,
      docTypeDropdown,
      headers,
      modeDropdown,
    };
  }

  public get preparedItems(): Array<string[]> {
    const {pointsByObject} = this;
    return [
      [pointsByObject.objectName],
      ...pointsByObject.pointList.map((point) => [point["типсчетчика"]]),
    ].map((arrayOfTd) => [...arrayOfTd, ""]);
  }

  public get minAndMaxDates(): string[] {
    const dateStringEndIndex = 7;
    if (this.openMonth !== null) {
      const dateStrings = Array(2).fill(
        this.openMonth.slice(0, dateStringEndIndex)
      );

      if (!this.modeDropdown.selectedItemIndex) {
        const [currentMonth, nextMonth]: Date[] = dateStrings.map(
          (_) => new Date()
        );

        nextMonth.setMonth(nextMonth.getMonth() + 1);

        return [currentMonth, nextMonth].map((date) =>
          date.toISOString().slice(0, dateStringEndIndex)
        );
      }

      return dateStrings;
    }
    return [];
  }

  public get modeDropdownActivator(): string {
    const {items, selectedItemIndex} = this.modeDropdown;

    return items[selectedItemIndex];
  }

  /**
   * Определить произошел ли выбор нового значения в одном из дропдаунов,
   * а с помощью свойства-наблюдателя syncValueChanged обрабатывать данный кейс.
   */
  public get wasChangeSyncValue(): string {
    const {dateDropdown, docTypeDropdown, modeDropdown} = this;

    return [dateDropdown.show, modeDropdown.show, docTypeDropdown.show].join();
  }

  public downloadTemplate(): void {
    const {
      dateDropdown,
      docTypeDropdown,
      downloadConsumptionDocument,
      downloadDocument,
    } = this;

    downloadConsumptionDocument(
      docTypeDropdown.selectedItemIndex,
      dateDropdown.date
    )
      .then((response) => downloadDocument(response))
      .catch(console.error);
  }

  public getChosenPointProps(index: number): IChosenAsdPointProps {
    const {modeDropdown, pointsByObject} = this;

    // Установить значение по умолчанию, соответствующее объекту потребления,
    // а в случае выбора ТУ (т.е. index > 0) переопределить его.
    const pointProps: IChosenAsdPointProps = {
      id: pointsByObject.objectId,
      asdId: pointsByObject.asdId,
      level: 1,
      mode: modeDropdown.selectedItemIndex,
      name: pointsByObject.objectName,
      puName: pointsByObject.puName,
      tuName: pointsByObject.tuName
    };

    let chosenPoint: IAskuePoint | undefined;

    if (
      index &&
      (chosenPoint = pointsByObject.pointList.find((_, i) => i === index - 1))
    ) {
      pointProps.id = chosenPoint["лицевой"];
      pointProps.asdId = chosenPoint["идентификаторасд"];
      pointProps.level = 0;
      pointProps.name = chosenPoint["названиету"];
      pointProps.tuName = chosenPoint["названиету"];
      pointProps.puName = chosenPoint["типсчетчика"];
      return pointProps;
    }

    return pointProps;
  }

  public onTableRow(index: number): void {
    const evtName = "update:dialogLoader";

    this.$emit(evtName, true);
    this.$emit("update:dialogTypeSlider", true);

    const {
      emitUpdatePoint,
      getChosenPointProps,
    } = this;

    const chosenPointProps = getChosenPointProps(index);
    emitUpdatePoint(chosenPointProps);
    this.$emit(evtName, false);
  }
}

export default AskueConsumptionTable;
