







































































































































































import {Component, Mixins} from "vue-property-decorator";
import {mapGetters, mapState} from "vuex";
import IAskuePointsByObject
  from "@/models/consumption-table/AskuePointsByObject.interface";
import IChosenAskuePointProps
  from "@/models/consumption-table/ChosenAskuePointProps.interface";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import AskueConsumptionTable
  from "@/components/for-turnovers-and-reports-view/AskueConsumptionTable.component.vue";
import InvisibleWall from "@/components/InvisibleWall.vue";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import XSlider from "@/components/SimpleSlider.vue";
import DropdownWithDatePickerWithoutRange
  from "../hoc/DropdownWithDatePickerWithoutRange.vue";
import ConsumedBarChart from "./ConsumedBarChart.vue";
import ConsumedChartAskue from "./ConsumedChartAskue.vue";
import AskueTable from "./AskueTable.vue";
import {OrganizationConsumption} from "@/models/organization";
import IChosenAsdPointProps
  from "@/models/consumption-table/ChosenAsdPointProps.interface";
import moment from 'moment';

const showDialog = {slider: false, loader: false};

@Component({
  components: {
    AskueConsumptionTable,
    InvisibleWall,
    Page,
    XNotice,
    XSlider,
    DropdownWithDatePickerWithoutRange,
    ConsumedBarChart,
    ConsumedChartAskue,
    AskueTable
  },
  computed: {
    ...mapGetters({
      contractId: "contract/id",
    }),
    ...mapState({asd: "asd"}),
    ...mapGetters("asd", {
      sortedPointsByObject: "sortedPointsByObject",
    }),
  },
})
class AskueTabContent extends Mixins(AppApiMixin) {
  [x: string]: any;
  dates: string[] = [];
  selectedDate: any;
  consumptionList: OrganizationConsumption[] = [];
  tableConsumptionList = [];
  tableHeaders = [];
  tableChannelsIds = [];
  contractPoints = [];

  // состояния кнопок меню
  viewMode = "grid";

  // тип отчета
  reportType = "month";
  reportDates: string[] = [];
  diffToggle: boolean = true;

  // каналы
  channelToggle: {};
  channelInfo: {};
  channelColors: string[] = [
    '#D17373',
    '#A1887F',
    '#8B9684',
    '#9575CD',
    '#7986CB',
    '#455A64',
    '#AED581',
    '#81C784',
    '#4DB6AC',
    '#FFB74D',
    '#64B5F6',
    '#4DD0E1',
  ];

  data() {
    const chosenPointProps: IChosenAsdPointProps = {
      id: 0,
      asdId: 0,
      level: 0,
      mode: 0,
      name: "",
      puName:"",
      tuName:""
    };

    return {
      chosenPointProps,
      showDialog: {...{slider: false, loader: false}},
      showNotice: false,
      showWall: false,
      channelToggle: this.channelToggle,
      selectedDate: this.selectedDate
    };
  }

  public updateChosenPoint(data) {
    this.chosenPointProps = data;
    this.updateChannels();
    this.updateTable();
  }

  public onChannelCheckboxClick(id, value) {
    this.updateChart();
  }

  public updateChannels() {
    const {contractId, getChannels, updateChart, reportType} = this
    const channelsPayload = {
      contractId,
      asdId: this.chosenPointProps.asdId,
      reportType,
      date: this.selectedDate,
    }
    getChannels(channelsPayload, (channelsList: []) => {
      this.channelToggle = channelsList['initialization'];
      this.channelInfo = channelsList['channels'];
      updateChart();
    }).finally(() => {
      showDialog.loader = false;
    });
  }

  public updateReportType() {
    /*this.updateChannels();
    this.updateTable();*/
    this.viewMode = "grid";
    if (this.reportType == "month") {
      this.monthDatepicker.$forceUpdate();
    }
    if (this.reportType == "day") {
      this.dayDatepicker.$forceUpdate();
    }
  }

  public get protectedSortedAskueByObject(): IAskuePointsByObject[] {
    return this.sortedPointsByObject || [];
  }

  public updateAllElements() {
    this.updateChart();
    this.updateTable();
  }

  public updateDate(data) {
    this.selectedDate = data;
    this.updateAllElements();
  }

  public updateTable() {
    const {
      contractId,
      channelToggle,
      reportType,
      diffToggle,
    } = this;
    const tablepayload = {
      contractId,
      asdId: this.chosenPointProps.asdId,
      channelToggle,
      reportType,
      diffToggle,
      selectedDate: this.selectedDate,
    };
    this.getTableAnalysis(
      tablepayload,
      (consumptionList: {}) => {
        this.tableConsumptionList = consumptionList['channels']['data'];
        this.tableHeaders = consumptionList['channels']['names'];
        this.tableChannelsIds = consumptionList['channels']['ids']
      }
    ).finally(() => {
      showDialog.loader = false;
    });
  }


  public mounted() {
    this.getContractPoints(this.contractId);
    this.updateChannels();
    this.updateTable();
  }

  public closeAllDialogs() {
    this.showDialog = {...showDialog};
  }

  public updateChart() {
    const {
      contractId,
      point_id: objId,
      dates,
      channelToggle,
      reportType,
      diffToggle,
    } = this;
    const [from, to] = dates;
    const tablepayload = {
      contractId,
      asdId: this.chosenPointProps.asdId,
      channelToggle,
      reportType,
      diffToggle,
      selectedDate: this.selectedDate,
    };

    this.getChannelsList(
      tablepayload,
      (consumptionList: {}) => {
        this.consumptionList = consumptionList['channels']['data'];
      }
    ).finally(() => {
      showDialog.loader = false;
    });
  }
}

export default AskueTabContent;
