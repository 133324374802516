









import {Component, Prop, Vue} from "vue-property-decorator";
import XChartAskue from "../hoc/ChartAskue.vue";

@Component({
  components: {XChartAskue},
})

class PaymentChart extends Vue {
  @Prop({required: true})
  readonly consumptionList!: [];
  @Prop({required: false})
  readonly reportType!: string;
  @Prop({required: true})
  readonly channelInfo!: [];
  @Prop({required: true})
  readonly channelIds!: [];

  fontFamily = "Roboto";
  fontSize = 14;
  labelColor = "#030724";

  // Легенда
  legendProps = {
    labels: {
      fontColor: this.labelColor,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
    },
  };

  // Ось X и ось Y
  axesCommonProps = {
    scaleLabel: {
      display: true,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontColor: this.labelColor,
    },
    ticks: {
      fontSize: this.fontSize,
      fontFamily: this.fontFamily,
      fontColor: "#bcc5d1",
    },
  };

  scalesProps = {
    xAxes: [
      {
        gridLines: {display: false},
        ticks: this.axesCommonProps.ticks,
        scaleLabel: {
          ...this.axesCommonProps.scaleLabel,
          labelString: "Период",
        },
      },
    ],
    yAxes: [
      {
        gridLines: {borderDash: [12], color: "#dce2ea"},
        ticks: this.axesCommonProps.ticks,
        scaleLabel: {
          ...this.axesCommonProps.scaleLabel,
          labelString: "Объем",
        },
      },
    ],
  };

  // Всплывающая подсказка
  tooltipGaps = {rowGap: 8, padding: 16};

  tooltipProps = {
    // Содержание и позиционирование
    mode: "index",
    position: "nearest",
    // Внешний вид контейнера
    backgroundColor: "#fff",
    borderColor: "#ebeff5",
    borderWidth: 1,
    cornerRadius: 8,
    // Заголовок
    titleFontColor: this.labelColor,
    titleFontFamily: this.fontFamily,
    titleFontSize: this.fontSize,
    titleMarginBottom: this.tooltipGaps.rowGap,
    // Тело
    bodyFontColor: "#525e74",
    bodyFontFamily: this.fontFamily,
    bodySpacing: this.tooltipGaps.rowGap,
    // Указатель
    caretPadding: 8,
    caretSize: 16,
    // Отступы
    xPadding: this.tooltipGaps.padding,
    yPadding: this.tooltipGaps.padding,
  };

  cssRules = {position: "relative", height: "550px", width: "100%"};

  public get preparedConsumptionList() {

    const {consumptionList} = this;

    let preparedConsumptionList = [];

    let parsedConsumptionList = JSON.parse(JSON.stringify(consumptionList))
    for (let i = 0; i < parsedConsumptionList.length; i++) {
      let dateInterval = parsedConsumptionList[i] === undefined ? [] : Object.keys(parsedConsumptionList[i]).reverse();
      let volume = parsedConsumptionList[i] === undefined ? [] : Object.values(parsedConsumptionList[i]).reverse();
      preparedConsumptionList.push({
        'datesInterval': dateInterval,
        'volume': volume,
      })
    }
    return preparedConsumptionList;
  }

  public get chartData() {
    let channelColors: string[] = [
      '#D17373',
      '#A1887F',
      '#8B9684',
      '#9575CD',
      '#7986CB',
      '#455A64',
      '#AED581',
      '#81C784',
      '#4DB6AC',
      '#FFB74D',
      '#64B5F6',
      '#4DD0E1',
    ];
    const {preparedConsumptionList} = this;
    let datasets = [];
    let datesInterval = [];
    let max = 0;
    for (let i = 0; i < preparedConsumptionList.length; i++) {
      let labelName = "label";
      if (this.channelInfo && Object.keys(this.channelInfo)) {
        labelName = this.channelInfo[this.channelIds[i]]['name'];
      }
      datasets.push({
        label: labelName,
        borderColor: channelColors[i],
        backgroundColor: channelColors[i],
        borderWidth: 2,
        fill: false,
        pointStyle: "circle",
        pointRadius: 4,
        data: preparedConsumptionList[i].volume,
      });
      if (preparedConsumptionList[i].datesInterval.length > max) {
        datesInterval = preparedConsumptionList[i].datesInterval;
        max = preparedConsumptionList[i].datesInterval.length;
      }
    }
    return {labels: datesInterval, datasets};
  }

  public get chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      scales: this.scalesProps,
      tooltips: this.tooltipProps,
    };
  }
}

export default PaymentChart;
